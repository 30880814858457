'use client';
import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import ShareRow from './ShareRow';
import { useLocalisation } from '../Localisation/hooks/useLocalisation';

interface IShareWrapper {}

export const ShareWrapper: React.FC<IShareWrapper> = () => {
    const [currentUrl, setCurrentUrl] = useState('');

    const { translations } = useLocalisation();

    useEffect(() => {
        setCurrentUrl(window.location.href);
    }, []);

    const copy = useCallback(() => {
        if (typeof navigator === 'undefined') return;

        void navigator.clipboard.writeText(currentUrl).then(() => {
            toast.success(translations.toastUrlCopied ?? 'URL copied.', {
                autoClose: 300,
                hideProgressBar: true,
                closeButton: false,
            });
        });
    }, [currentUrl, translations.toastUrlCopied]);

    return (
        <>
            <section className="relative flex flex-row items-center justify-center gap-2">
                <ShareRow />
                <button
                    className="flex h-[30px] w-[30px] items-center justify-center"
                    onClick={copy}
                >
                    <FontAwesomeIcon icon={faCopy} className="h-6 text-neutral-400" />
                </button>
            </section>
        </>
    );
};
