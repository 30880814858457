'use client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
    faFacebook,
    faLinkedin,
    faWhatsappSquare,
    faXing,
} from '@fortawesome/free-brands-svg-icons';
import { FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from 'react-share';
import { faShare } from '@fortawesome/free-solid-svg-icons';

const ShareRow: React.FC = () => {
    const [currentUrl, setCurrentUrl] = useState('');
    const [showShare, setShowShare] = useState(false);

    useEffect(() => {
        setCurrentUrl(window.location.href);
    }, []);

    useEffect(() => {
        if (typeof navigator === 'undefined') return undefined;
        setShowShare(true);
    }, []);

    return (
        <>
            <div className="relative flex flex-row">
                <div className="relative p-3 text-2xl text-xing/80 transition-all hover:scale-110 hover:text-xing">
                    <a
                        href={`https://www.xing.com/spi/shares/new?url=${currentUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        aria-label="share xing"
                    >
                        <FontAwesomeIcon icon={faXing} />
                    </a>
                </div>
                <div className="relative p-3 text-2xl text-linkedin/80 transition-all hover:scale-110 hover:text-linkedin">
                    <LinkedinShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faLinkedin} />
                    </LinkedinShareButton>
                </div>
                <div className="relative p-3 text-2xl text-facebook/80 transition-all hover:scale-110 hover:text-facebook">
                    <FacebookShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faFacebook} />
                    </FacebookShareButton>
                </div>
                <div className="relative p-3 text-2xl text-whatsapp/80 transition-all hover:scale-110 hover:text-whatsapp">
                    <WhatsappShareButton url={currentUrl}>
                        <FontAwesomeIcon icon={faWhatsappSquare} />
                    </WhatsappShareButton>
                </div>

                {showShare && (
                    <div className="relative p-3 text-2xl text-primary/80 transition-all hover:scale-110 hover:text-primary">
                        <button
                            name="share"
                            aria-label="share"
                            type="button"
                            onClick={() => {
                                if (typeof navigator === 'undefined') return;
                                // eslint-disable-next-line no-void
                                void navigator.share({ url: currentUrl });
                            }}
                        >
                            <FontAwesomeIcon icon={faShare} />
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default ShareRow;
